import en from 'lang/en.json'
import es from 'lang/es.json'
import AgentProfileContain, { IAgentProfileContain } from 'shared/components-pages/profiles/agent-profile'
import { getAgent } from 'api/agent.request'
import { IAgentResponse } from 'shared/models/interfaces/response/agent.interface'
import React from 'react'
import type { GetServerSideProps, NextPage } from "next";
import { useSessionStorage } from 'usehooks-ts'
import { useRouter } from 'next/router'


export const getServerSideProps: GetServerSideProps = async (context) => {
  /* @ts-ignore */
  const params = context.params as any;
  const id = params.params[1];
  const data = await getAgent(id)

  if (data.status === 404 || data.status === 500) {
    return {
      redirect: {
        destination: "/listado/agentes",
        permanent: false,
      },
    };
  }

  return {
    props: {
      data: data,
      id: params.params[1],
      messages: context.locale === "es" ? es : en,
      url: context.resolvedUrl,
    },
    
  };
};


const AgenteProfilePage = (props: AgentProfilePage) => {
  const [code, setCode] = useSessionStorage<{id:any,type:any}>("code", { id: null, type: null });
  const router = useRouter()
  const configProfile: IAgentProfileContain = {
    id: props.id,
    IsUserCollection: false,
    data: props.data
  };
  
  React.useEffect(() => {

    setCode({ id: props.id, type: "referer" })
    router.push(router.asPath, undefined, { shallow: true });
  }, []);
  return <AgentProfileContain {...configProfile} />
};

export default AgenteProfilePage;



export interface AgentProfilePage {
  data: IAgentResponse,
  id: number,
  messages: any,
  url: string,
}


